import { Options, Vue } from "vue-class-component";
import DropdownSearchMenu from "./dropdown-search-menu/dropdown-search-menu.vue";

@Options<DropdownSearch>({
    name: "app-dropdown-search",
    components: {
        "app-dropdown-search-menu": DropdownSearchMenu,
    },
    props: {
        modelValue: String,
        searchFunction: Function,
        disabledTableRows: {
            type: Boolean,
            default: false,
        },
        disabledTableExport: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: () => "md",
        },
        side: {
            type: String,
            default: () => "right",
        },
    },
    emits: ["update:modelValue"],
})
export default class DropdownSearch extends Vue {
    private searchFunction: any;
    private dropdownSearchElement: HTMLElement;
    public isOpen = false;

    public mounted(): void {
        this.dropdownSearchElement = this.$refs.dropdownSearch as HTMLElement;
        document.addEventListener("click", this.documentClick);
    }

    public unmounted(): void {
        document.removeEventListener("click", this.documentClick);
    }

    public onSearchKeyChange(event: any) {
        this.$emit("update:modelValue", event.target.value);
    }

    private documentClick(event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        if (this.dropdownSearchElement !== target && !this.dropdownSearchElement.contains(target)) {
            this.isOpen = false;
        }
    }

    private toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    //修改tableRows
    public tableRowsModalOpen() {
        (this.$refs.tableRowsModal as any).openModal();
    }
    public tableRowsFunction(value: string) {
        if (this.$tools.isEmpty(value)) return;
        if (!this.$tools.isNumber(value)) return;
        this.$store.dispatch("ui/setTableRows", Number(value));
        this.searchFunction("refresh");
    }
    public tableExportFunction() {
        this.searchFunction("export");
    }

    get getTableRows() {
        return this.$store.getters["ui/tableRows"];
    }
}
